import PropTypes from "prop-types";
import { addMinutes } from "date-fns";
import * as Yup from "yup";
import { Formik } from "formik";
import MobileDateTimePicker from "@material-ui/lab/MobileDateTimePicker";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  Autocomplete,
} from "@material-ui/core";
import TrashIcon from "../../../icons/Trash";
import { experimentalStyled } from "@material-ui/core/styles";
import {
  updateCustomerEvent,
  createCustomerEvent,
  deleteCustomerEvent,
} from "../../../slices/calendar";
import { useDispatch, useSelector } from "../../../store";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../contexts/DataContext";
import SimpleLoader from "../../SimpleLoader";
import { useTranslation } from "react-i18next";

const iconsMap = {
  0: ClearIcon,
  1: ArrowForwardIcon,
  2: ArrowForwardIcon,
  3: BusinessCenterIcon,
  4: ClearIcon,
};

const CircleAvatar = experimentalStyled("div")({
  width: 40,
  height: 40,
  borderRadius: "50%",
  backgroundColor: "#ccc",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: 8,
  color: "#fff",
  fontWeight: "bold",
});

const getInitialValues = (event, range) => {
  if (event) {
    return {
      allDay: event.allDay || false,
      color: event.color || "",
      description: event.description || "",
      end: event.end ? new Date(event.end) : addMinutes(new Date(), 30),
      start: event.start ? new Date(event.start) : new Date(),
      interaction: event.interaction || "",
      submit: null,
      next_interaction: event.next_interaction || "",
      next_end: event.next_end ? new Date(event.next_end) : new Date(),
      next_start: event.next_start ? new Date(event.next_start) : new Date(),
      next_description: event.next_description || "",
      result: +event.result || "",
      customer_contact_id: event.customer_contact_id || "",
      customer_id: event.customer_id || "",
      manager_id: event.manager_id || "",
    };
  }

  if (range) {
    return {
      allDay: false,
      color: "",
      description: "",
      end: new Date(range.end),
      start: new Date(range.start),
      interaction: "",
      submit: null,
      next_interaction: "",
      next_end: addMinutes(new Date(), 30),
      next_start: new Date(),
      next_description: "",
      result: "",
      customer_contact_id: "",
      customer_id: "",
      manager_id: "",
    };
  }

  return {
    allDay: false,
    color: "",
    description: "",
    end: addMinutes(new Date(), 30),
    start: new Date(),
    interaction: "",
    submit: null,
    next_interaction: "",
    next_end: addMinutes(new Date(), 30),
    next_start: new Date(),
    next_description: "",
    result: "",
    customer_contact_id: "",
    customer_id: "",
    manager_id: "",
  };
};

const CalendarCustomerForm = (props) => {
  const {
    event,
    onAddComplete,
    onCancel,
    onDeleteComplete,
    onEditComplete,
    range,
    customer_contact_id,
    customer_id,
    manager_id,
    getData,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: contextData, isLoading } = useContext(DataContext);
  const {
    customer_interaction_actions,
    customer_interaction_results,
    interaction_customers,
    customer_contacts,
  } = contextData;

  const [interactionCustomers, setInteractionCustomers] = useState([]);

  const actualContact = customer_contacts.filter((item) => !item.deleted);

  const getCustomers = async () => {
    try {
      const res = await HttpService.post(
        UrlService.apiDomain() + `api/customers/get/all`,
        { data: {} }
      );
      if (!res.data.success) {
        alert(res.data.message);
      }
      setInteractionCustomers(Object.values(res.data.data) ?? []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  const handleDelete = async () => {
    try {
      await dispatch(deleteCustomerEvent(event.id));
      getData?.("customer_interactions", true);
      onDeleteComplete?.();
    } catch (err) {
      console.error(err);
    }
  };

  const renderAvatar = () => {
    const initials =
      event.nabor &&
      event.nabor
        .split(" ")
        .map((name) => name.charAt(0))
        .join("");
    return <CircleAvatar>{initials}</CircleAvatar>;
  };

  const disabled = !!event?.result;

  const getTitle = (customerContactId, interaction) => {
    if (customer_contact_id) {
      const name = customer_contacts.find(
        (item) => item.id == customer_contact_id
      ).name;

      if (interaction == 1) {
        return `Zavolejte ${name}`;
      } else return `Setkání s ${name}`;
    }

    if (!event && !customer_contact_id) {
      const name = customer_contacts.find(
        (item) => item.id == customerContactId
      ).name;
      if (interaction == 1) {
        return `Zavolejte ${name}`;
      } else return `Setkání s ${name}`;
    } else {
      const name = customer_contacts.find(
        (item) => item.id == customerContactId
      ).name;
      if (interaction == 1) {
        return `Zavolejte ${name}`;
      } else return `Setkání s ${name}`;
    }
  };

  if (isLoading) {
    return <SimpleLoader />;
  }
  return (
    <>
      <Formik
        initialValues={getInitialValues(event, range)}
        validationSchema={Yup.object().shape({
          // allDay: Yup.bool(),
          description: Yup.string().max(5000),
          next_description: Yup.string().max(5000),
          end: Yup.date().when(
            "start",
            (start, schema) =>
              start &&
              schema.min(
                start,
                t("Datum ukončení musí být pozdější než datum zahájení")
              )
          ),
          start: Yup.date(),
          next_start: Yup.date(),
          next_end: Yup.date().when(
            "next_start",
            (next_start, schema) =>
              next_start &&
              schema.min(
                next_start,
                t("Datum ukončení musí být pozdější než datum zahájení")
              )
          ),
          interaction: Yup.string().max(255).required(t("Interakce povinna")),
          customer_id: customer_id
            ? Yup.string().max(255)
            : Yup.string().max(255).required(t("Povinne")),
          result: Yup.string(),
          vacancy: Yup.string(),
          customer_contact_id: Yup.string().test(
            "required",
            t("Přidejte osobu"),
            function (value) {
              if (event == null && !customer_contact_id) {
                return value !== undefined && value !== "";
              }
              return true;
            }
          ),
          next_interaction: Yup.string().when("result", {
            is: (value) => value == 1 || value == 2,
            then: Yup.string().max(255).required(t("Určete další akci")),
          }),
        })}
        onSubmit={async (
          values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            const data_current = {
              // allDay: values.allDay,
              id: event ? event.id : "",
              description: values.description,
              vacancy: values.vacancy,
              end: values.end,
              start: values.start,
              interaction: values.interaction,
              customer_contact_id:
                customer_contact_id ||
                values.customer_contact_id ||
                event.customer_contact_id,
              color: values.result
                ? "#bdc2cb"
                : values.interaction == 1
                ? "#eee572"
                : "#43a048",
              title: event
                ? getTitle(event.customer_contact_id, values.interaction)
                : getTitle(values.customer_contact_id, values.interaction),
              result: values.result == 0 ? null : values.result == 0,
              customer_id: customer_id || values.customer_id,
              manager_id:
                manager_id ??
                JSON.parse(localStorage.getItem("my_employee")).id,
            };

            let data_next = {
              interaction: values.next_interaction,
              customer_id: customer_id || values.customer_id,
              customer_contact_id:
                customer_contact_id ||
                values.customer_contact_id ||
                event.customer_contact_id,
              start: values.next_start,
              end: values.next_end,
              description: values.next_description,
              color: values.next_interaction == 1 ? "#eee572" : "#43a048",
              title:
                event &&
                getTitle(event.customer_contact_id, values.next_interaction),
            };

            if (values.result != 1 && values.result != 2) {
              data_next = null;
            }

            if (event) {
              await dispatch(
                updateCustomerEvent({
                  id: event.id,
                  data_current,
                  data_next: data_next,
                })
              );
              // getData?.("customer_interactions", true);

              if (!event && onAddComplete) {
                onAddComplete();
              }

              if (event && onEditComplete) {
                onEditComplete();
              }
            } else {
              // await dispatch(createEvent(data));
              await dispatch(createCustomerEvent(data_current));
              resetForm();
              // getData?.("customer_interactions", true);
              setStatus({ success: true });
              setSubmitting(false);

              if (!event && onAddComplete) {
                onAddComplete();
              }

              if (event && onEditComplete) {
                onEditComplete();
              }
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ p: 3 }}>
              <Typography align="center" color="textPrimary" variant="h5">
                {event ? t("Upravit událost") : t("Přidat událost")}
              </Typography>
            </Box>
            {event && (
              <Box
                sx={{
                  display: "inline-flex",
                  width: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 3,
                }}
              >
                <Box>
                  <Typography align="left" color="textPrimary" variant="h6">
                    {getTitle(event.customer_contact_id, event.interaction)},
                  </Typography>
                  <a
                    href={`tel:${
                      customer_contacts.find(
                        (cont) => cont.id == event.customer_contact_id
                      ).phone
                    }`}
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      variant="body1"
                      color="primary"
                      style={{ cursor: "pointer" }}
                    >
                      {
                        customer_contacts.find(
                          (cont) => cont.id == event.customer_contact_id
                        ).phone
                      }
                    </span>
                  </a>
                </Box>
                {/*  <Box>
                  <Tooltip title={event.nabor || ""}>{renderAvatar()}</Tooltip>
                 </Box> */}
              </Box>
            )}

            <Typography align="center" color="textPrimary" variant="subtitle2">
              {t("Aktuální")}
            </Typography>
            <Divider />
            <Box sx={{ p: 3 }}>
              {!customer_id && (
                <FormControl fullWidth>
                  <Autocomplete
                    disabled={disabled || event}
                    name={`customer_id`}
                    options={interactionCustomers}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      setFieldValue(`customer_id`, value?.id);
                    }}
                    onBlur={handleBlur}
                    value={
                      // customer_id ??
                      interactionCustomers.find(
                        (customer) => customer.id == values.customer_id
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Uživatel")}
                        error={!!(touched.customer_id && errors.customer_id)}
                        helperText={touched.customer_id && errors.customer_id}
                      />
                    )}
                  />
                </FormControl>
              )}
              {!event && !customer_contact_id && (
                <FormControl sx={{ mt: 2 }} fullWidth>
                  <Autocomplete
                    disabled={disabled}
                    name={`customer_contact_id`}
                    options={
                      customer_id
                        ? actualContact.filter(
                            (item) => item.customer == customer_id
                          )
                        : actualContact.filter(
                            (item) => item.customer == values.customer_id
                          )
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      setFieldValue(`customer_contact_id`, value?.id);
                    }}
                    onBlur={handleBlur}
                    value={
                      customer_contacts.find(
                        (person) => person.id === values.customer_contact_id
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Přidat osobu")}
                        error={
                          !!(
                            touched.customer_contact_id &&
                            errors.customer_contact_id
                          )
                        }
                        helperText={
                          touched.customer_contact_id &&
                          errors.customer_contact_id
                        }
                      />
                    )}
                  />
                </FormControl>
              )}
              <FormControl
                sx={{ mt: 2 }}
                error={Boolean(touched.interaction && errors.interaction)}
                fullWidth
                variant="outlined"
              >
                <InputLabel id="interaction">{t("Interakce")}</InputLabel>
                <Select
                  disabled={disabled}
                  id="interaction"
                  label={t("Interakce")}
                  name="interaction"
                  value={values.interaction}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {customer_interaction_actions.map((action) => (
                    <MenuItem key={action.id} value={action.id}>
                      {t(action.name)}
                    </MenuItem>
                  ))}
                </Select>
                {touched.interaction && errors.interaction && (
                  <FormHelperText>{errors.interaction}</FormHelperText>
                )}
              </FormControl>
              <Box sx={{ mt: 2 }}>
                <TextField
                  disabled={disabled}
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label={t("Popis")}
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{
                  display: "inline-flex",
                  width: 1,
                  gap: 2,
                  mt: 2,
                }}
              >
                <Box sx={{ width: "50%" }}>
                  <MobileDateTimePicker
                    disabled={disabled}
                    inputFormat="dd.MM.yyyy, HH:mm"
                    ampm={false}
                    label={t("Zahájení")}
                    onChange={(date) => {
                      setFieldValue("start", date);
                      setFieldValue("end", addMinutes(date, 30));
                    }}
                    renderInput={(inputProps) => (
                      <TextField fullWidth variant="outlined" {...inputProps} />
                    )}
                    value={values.start}
                  />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <MobileDateTimePicker
                    disabled={disabled}
                    inputFormat="dd.MM.yyyy, HH:mm"
                    ampm={false}
                    label={t("Ukončení")}
                    onChange={(date) => setFieldValue("end", date)}
                    renderInput={(inputProps) => (
                      <TextField fullWidth variant="outlined" {...inputProps} />
                    )}
                    value={values.end}
                  />
                </Box>
              </Box>
              {Boolean(touched.end && errors.end) && (
                <Box sx={{ mt: 2 }}>
                  <FormHelperText error>{errors.end}</FormHelperText>
                </Box>
              )}
              {event && (
                <FormControl
                  sx={{ mt: 2 }}
                  error={Boolean(touched.result && errors.result)}
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel id="result">{t("Výsledek")}</InputLabel>
                  <Select
                    disabled={disabled}
                    id="result"
                    label={t("Výsledek")}
                    name="result"
                    value={values.result}
                    onChange={(e, v) => {
                      console.log({ e });
                      console.log({ v });
                      handleChange(e, v);
                      if (e.target.value == 1) {
                        setFieldValue(`next_interaction`, 1);
                      } else if (e.target.value == 2) {
                        setFieldValue(`next_interaction`, 2);
                      }
                    }}
                    onBlur={handleBlur}
                  >
                    {customer_interaction_results.map((res) => {
                      const Icon = iconsMap[res.id];
                      return (
                        <MenuItem key={res.id} value={res.id}>
                          <Box
                            sx={{
                              display: "inline-flex",
                              width: 1,
                              justifyContent: "space-between",
                            }}
                          >
                            {t(res.name)}
                            <Icon fontSize="small" />
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {touched.result && errors.result && (
                    <FormHelperText>{errors.result}</FormHelperText>
                  )}
                </FormControl>
              )}
              {(values.result == 1 || values.result == 2) &&
                event.color !== "#bdc2cb" && (
                  <>
                    <Typography
                      sx={{ mt: 2 }}
                      align="center"
                      color="textPrimary"
                      variant="subtitle2"
                    >
                      {t("Další akce")}
                    </Typography>
                    <Divider />
                    <FormControl
                      error={Boolean(
                        touched.next_interaction && errors.next_interaction
                      )}
                      fullWidth
                      variant="outlined"
                      sx={{ mt: 2 }}
                    >
                      <InputLabel id="next_interaction">
                        {t("Interakce")}
                      </InputLabel>
                      <Select
                        disabled={disabled}
                        id="next_interaction"
                        label={t("Interakce")}
                        name="next_interaction"
                        value={values.next_interaction}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {customer_interaction_actions.map((action) => (
                          <MenuItem key={action.id} value={action.id}>
                            {t(action.name)}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.next_interaction && errors.next_interaction && (
                        <FormHelperText>
                          {errors.next_interaction}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        disabled={disabled}
                        error={Boolean(
                          touched.next_description && errors.next_description
                        )}
                        fullWidth
                        helperText={
                          touched.next_description && errors.next_description
                        }
                        label={t("Popis")}
                        name="next_description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.next_description}
                        variant="outlined"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "inline-flex",
                        width: 1,
                        gap: 2,
                        mt: 2,
                      }}
                    >
                      <Box sx={{ width: "50%" }}>
                        <MobileDateTimePicker
                          disabled={disabled}
                          inputFormat="dd.MM.yyyy, HH:mm"
                          ampm={false}
                          label={t("Zahájení")}
                          onChange={(date) => {
                            setFieldValue("next_start", date);
                            setFieldValue("next_end", addMinutes(date, 30));
                          }}
                          renderInput={(inputProps) => (
                            <TextField
                              fullWidth
                              variant="outlined"
                              {...inputProps}
                            />
                          )}
                          value={values.next_start}
                        />
                      </Box>
                      <Box sx={{ width: "50%" }}>
                        <MobileDateTimePicker
                          disabled={disabled}
                          inputFormat="dd.MM.yyyy, HH:mm"
                          ampm={false}
                          label={t("Ukončení")}
                          onChange={(date) => setFieldValue("next_end", date)}
                          renderInput={(inputProps) => (
                            <TextField
                              fullWidth
                              variant="outlined"
                              {...inputProps}
                            />
                          )}
                          value={values.next_end}
                        />
                      </Box>
                    </Box>
                    {Boolean(touched.next_end && errors.next_end) && (
                      <Box sx={{ mt: 2 }}>
                        <FormHelperText error>{errors.next_end}</FormHelperText>
                      </Box>
                    )}
                  </>
                )}
              {/* <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.allDay}
                      color="primary"
                      name="allDay"
                      onChange={handleChange}
                    />
                  }
                  label="All day"
                />
              </Box> */}
            </Box>
            <Divider />
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                p: 2,
              }}
            >
              {event && (
                <IconButton onClick={() => handleDelete()}>
                  <TrashIcon fontSize="small" />
                </IconButton>
              )}
              <Box sx={{ flexGrow: 1 }} />
              <Button color="primary" onClick={onCancel} variant="text">
                {t("Zrušení")}
              </Button>
              <Button
                color="primary"
                disabled={isSubmitting}
                sx={{ ml: 1 }}
                type="submit"
                variant="contained"
              >
                {t("Uložit")}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

CalendarCustomerForm.propTypes = {
  event: PropTypes.object,
  onAddComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  range: PropTypes.object,
};

export default CalendarCustomerForm;
