"use strict";


import StorageT from "../../../../../Interface/Storage/StorageT.mjs";

import UIPageContentAbstractT from "../../../../../Interface/UI/Page/Content/_/UIPageContentAbstractT.mjs";

import UIPageContentProducerAbstractT from "../../../../../Interface/UI/Page/Content/_/UIPageContentProducerAbstractT.mjs";

import UIPageContentTopBarProducerAbstractT from "../../../../../Interface/UI/Page/Content/TopBar/_/UIPageContentTopBarProducerAbstractT.mjs";

import UIPageContentTopBarFilterSwitcherProducerAbstractT from "../../../../../Interface/UI/Page/Content/TopBar/FilterSwitcher/_/UIPageContentTopBarFilterSwitcherProducerAbstractT.mjs";

import UIGridProducerAbstractT from "../../../../../Interface/UI/Grid/_/UIGridProducerAbstractT.mjs";

import UIButtonProducerAbstractT from "../../../../../Interface/UI/Button/_/UIButtonProducerAbstractT.mjs";

import UIBooleanProducerAbstractT from "../../../../UI/Boolean/_/UIBooleanProducerAbstractT.mjs";

import UILabelProducerAbstractT from "../../../../UI/Label/_/UILabelProducerAbstractT.mjs";

import ViewPageAbstractT from "../../../../../Procedure/View/Page/_/ViewPageAbstractT.mjs";

import APIClientT from "../../../../../Procedure/API/APIClientT.mjs";

import UIGridColumnCreatorT from "../../../../../Procedure/UI/Grid/Column/UIGridColumnCreatorT.mjs";

import JSONRPCRequestT from "../../../../../Type/JSONRPC/JSONRPCRequestT.mjs";

import SVGRefreshT from "../../../../../Type/SVG/SVGRefreshT.mjs";

import TranslationGridCST from "../../../../../Type/Translation/Grid/TranslationGridCST.mjs";

import TranslationGridENT from "../../../../../Type/Translation/Grid/TranslationGridENT.mjs";

import TranslationGridRUT from "../../../../../Type/Translation/Grid/TranslationGridRUT.mjs";


const ViewPageEmploymentDepartnentInfosT = class ViewPageEmploymentDepartnentInfosT extends ViewPageAbstractT {

	#APIClient = null;
	
	#Storage = null;
	
	#UIPageContent = null;
	
	#UIGrid = null;
	
	#UIPageContentTopBar = null;
	
	#UIPageContentTopBarFilterSwitcher = null;
	
	#UIButtonSend = null;
	
	#UIButtonRecreate = null;
	
	#UIButtonDelete = null;
	
	#UIGridColumnCreator = null;
	
	#Title = null;
	
	#Properties = null;
	
	#Data = null;
	
	
	#SelectedRowIds = [ ];
	
	
	#InProcessSend = false;
	
	#InProcessRecreate = false;
	
	#InProcessDelete = false;
	
	
	#UIGridProducer = null;

	#UIPageContentProducer = null;
	
	#UIPageContentTopBarProducer = null;
	
	#UIPageContentTopBarFilterSwitcherProducer = null;
	
	#UIButtonProducer = null;
	
	#UIBooleanProducer = null;
	
	#UILabelProducer = null;
	
	#UISVGProducer = null;
	
	#UIGridComponents = null;
	
	#Language = null;
	
	
	#ModelName = null;


	constructor(
		UIGridProducer,
		UIPageContentProducer,
		UIPageContentTopBarProducer,
		UIPageContentTopBarFilterSwitcherProducer,
		UIButtonProducer,
		UIBooleanProducer,
		UILabelProducer,
		UISVGProducer,
		APIClient,
		Storage,
		UIGridComponents,
		Language
	){
		
		if( ( UIGridProducer instanceof UIGridProducerAbstractT ) === false ){
		
			throw new Error( "Argument" );
		
		}
		
		if( ( UIPageContentProducer instanceof UIPageContentProducerAbstractT ) === false ){
		
			throw new Error( "Argument" );
		
		}
		
		if( ( UIPageContentTopBarProducer instanceof UIPageContentTopBarProducerAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( UIPageContentTopBarFilterSwitcherProducer instanceof UIPageContentTopBarFilterSwitcherProducerAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( UIButtonProducer instanceof UIButtonProducerAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( UIBooleanProducer instanceof UIBooleanProducerAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( UILabelProducer instanceof UILabelProducerAbstractT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		
		if( ( APIClient instanceof APIClientT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( ( Storage instanceof StorageT ) === false ){
			
			throw new Error( "Argument" );
			
		}
		
		if( typeof( Language ) !== "string" ){
			
			throw new Error( "Argument" );
			
		}
		
		
		super( );
		
		
		this.#UIGridProducer = UIGridProducer;
		
		this.#UIPageContentProducer = UIPageContentProducer;
		
		this.#UIPageContentTopBarProducer = UIPageContentTopBarProducer;
		
		this.#UIPageContentTopBarFilterSwitcherProducer = UIPageContentTopBarFilterSwitcherProducer;
		
		this.#UIButtonProducer = UIButtonProducer;
		
		this.#UIBooleanProducer = UIBooleanProducer;
		
		this.#UILabelProducer = UILabelProducer;
		
		this.#UISVGProducer = UISVGProducer;
		
		this.#UIGridComponents = UIGridComponents;
		
		this.#Language = Language;
		
		this.#APIClient = APIClient;
		
		this.#Storage = Storage;
		
		this.#UIGridColumnCreator = new UIGridColumnCreatorT( );
		
		
		this.#ModelName = "employment_department_infos";
		
	}
	
	FilterSwitcherOnChange( Value ){
		
		console.log( "ViewPageEmploymentDepartnentInfosT.FilterSwitcherOnChange", Value );
		
		
		var FilterModel = this.#UIGrid.FilterModelGet( );
					
		if( Value === "Active" ){
			
			FilterModel[ "response_status" ] = {
				type: "null"
			};
			
		} else if( Value === "Archive" ){
			
			FilterModel[ "response_status" ] = {
				type: "notNull"
			};
			
		} else if( Value === "All" ){
			
			FilterModel[ "response_status" ] = null;
			
		} else {
			
			throw new Error( "Argument" );
			
		}
		
		this.#UIGrid.FilterModelSet( FilterModel );

		this.#UIGrid.FilterChanged( );
					
	}
	
	Execute( ){
		
		let TranslationGrid = null;
		
		if( this.#Language === "EN" ){
			
			TranslationGrid = TranslationGridENT.Data( );
			
		} else if( this.#Language === "RU" ){
			
			TranslationGrid = TranslationGridRUT.Data( );
			
		} else {
			
			TranslationGrid = TranslationGridCST.Data( );
			
		}
		
		
		this.#UIGrid = this.#UIGridProducer.Produce(
			{	
				className: "ag-theme-material",
				
				style: { 
					width: "100%", 
					border: "1px solid black",
					height: "100%"
				},
				
				columnDefs: [
					
				],

				rowData: [ 
					
				],
				
				rowSelection: "multiple",
				
				onCellValueChanged: this.OnColumnChange.bind( this ),
				
				onSelectionChanged: this.OnSelectionChanged.bind( this ),
				
				onFirstDataRendered: this.OnFirstDataRendered.bind( this ),
				
				frameworkComponents: this.#UIGridComponents,
				
				localeText: TranslationGrid,
				
				getRowNodeId: this.RowNodeId.bind( this ),
				
				key: this.#ModelName + "_" + "grid"
				
			},
			
			this.UIGridOnReady.bind( this )
			
		);
		
		
		this.#UIPageContentTopBar = this.#UIPageContentTopBarProducer.Produce( 
			{
				key: this.#ModelName + "_" + "top_bar"
			},
			
			this.UIPageContentTopBarOnReady.bind( this )
		);

		
		this.#UIPageContent = this.#UIPageContentProducer.Produce( 
			{
				key: this.#ModelName,
				
				StylePageContentBox: {
					backgroundColor: "background.default",
					height: "100%",
					paddingTop: "4px",
					paddingBottom: "4px"
				}, 
				StylePageContentBoxContainer: {
					height: "100%",
					maxWidth: "100%"
				},
				StylePageContentBoxContainerBoxTopBar: {
					paddingTop: "20px", 
					paddingBottom: "20px"
				},
				StylePageContentBoxContainerBoxGrid: { 
					width: "100%", 
					height: "calc(90% - 50px)",
					marginTop: "3px"
				}
			},
			
			this.UIPageContentOnReady.bind( this )
			
		);
		
		
		return this.#UIPageContent.Component( );
		
	}
	
	OnFirstDataRendered( Params ) {
		
		console.log( "OnFirstDataRendered", Params );
		
		this.#UIPageContentTopBarFilterSwitcher.PropSet(
			"Value",
			"Active"
		);
		
	}
	
	RowNodeId( Row ){ 

		return Row.id;
		
	}
	
	UIPageContentTopBarOnReady(
	
	){
				
		console.log( "ViewPageEmploymentDepartnentInfosT.UIPageContentTopBarOnReady" );
		
		
		let ConfigModels = this.#Storage.Get( "config.models" );
		
		let ModelTitle = null;
		
		for(
			let I = 0;
			I < ConfigModels.length;
			I++
		){
			
			if( ConfigModels[ I ].name === this.#ModelName ){
				
				ModelTitle = ConfigModels[ I ].full_name;
				
				break;
				
			}
			
		}
		
		
		if( ModelTitle === null ){
			
			throw new Error( "Invalid model" );
			
		}
		
		
		this.#Title = this.#UILabelProducer.Produce( {
			key: this.#ModelName + "_" + "title",
			SX: { },
			Variant: "h5",
			Color: "textPrimary",
			Children: [ ModelTitle ] //TODO Translator
		} );
		
		
		
		this.#UIPageContentTopBarFilterSwitcher = this.#UIPageContentTopBarFilterSwitcherProducer.Produce( 
			{
				key: this.#ModelName + "_" + "filter_switcher",
				OnChange: this.FilterSwitcherOnChange.bind( this )
			},
			[
				
			]
		);
		
		
		
		this.#UIButtonSend = this.#UIButtonProducer.Produce( 
			{
				key: this.#ModelName + "_" + "button_send",
				Children: [
				
					this.#UISVGProducer.Produce(
						{
							key: this.#ModelName + "_" + "button_send_svg",
							SX: { },
							Data: SVGRefreshT.Data
						}
					)
		
				],
				Disabled: true,
				OnClick: this.UIButtonSendClick.bind( this )
			}
		);
		
		this.#UIButtonRecreate = this.#UIButtonProducer.Produce( 
			{
				key: this.#ModelName + "_" + "button_recreate",
				Children: [ 
					this.#UILabelProducer.Produce(
						{
							key: this.#ModelName + "_" + "label_recreate",
							
							SX: { },
							Variant: "button",
							Color: "default",
							Children: [ "Znovu vytvořit" ] //TODO Translator
						}
					)
				],
				Disabled: true,
				OnClick: this.UIButtonRecreateClick.bind( this )
			}
		);
		
		this.#UIButtonDelete = this.#UIButtonProducer.Produce( 
			{
				key: this.#ModelName + "_" + "button_delete",
				Children: [ 
					this.#UILabelProducer.Produce(
						{
							key: this.#ModelName + "_" + "label_delete",
							
							SX: { },
							Variant: "button",
							Color: "default",
							Children: [ "Odstranit" ] //TODO Translator
						}
					)
				],
				Disabled: true,
				OnClick: this.UIButtonDeleteClick.bind( this )
			}
		);
		
		
		
		this.#UIPageContentTopBar.ChildrenSet( [ 
			{
				key: this.#ModelName + "_" + "top_bar" + "_" + "grid_title",
				
				XS: null,
				Components: [ this.#Title ]
				
			},
			{
				key: this.#ModelName + "_" + "top_bar" + "_" + "grid_filter_switcher",
				
				XS: null,
				Components: [ this.#UIPageContentTopBarFilterSwitcher ]
			},
			
			{
				
				key: this.#ModelName + "_" + "top_bar" + "_" + "grid_buttons",
				
				XS: null, 
				Components: [ 
					this.#UIButtonSend,
			
					this.#UIButtonRecreate,
			
					this.#UIButtonDelete
				]
				
			}
			
		] );
		
	}
	
	UIButtonSendClick( 
	
	){
		
		this.#InProcessSend = true;
		
		
		let UISVG = this.#UIButtonSend.PropGet( "Children" )[ 0 ];
		
		console.log( UISVG );
		
		UISVG.PropSet(
			"SX", 
			{
				animation: "spin 2s linear infinite",
				"@keyframes spin": {
					"0%": {
						transform: "rotate(-360deg)",
					},
					"100%": {
						transform: "rotate(0deg)",
					}
				}
			} 
		);
		
		
		this.#UIButtonSend.PropSet( "Disabled", true );
		
		this.#UIButtonRecreate.PropSet( "Disabled", true );
		
		this.#UIButtonDelete.PropSet( "Disabled", true );
		
		
		let JSONRPCRequests = [ ];
		
		for(
			let I = 0;
			I < this.#SelectedRowIds.length;
			I++
		){
			
			let JSONRPCRequest = new JSONRPCRequestT(
				this.#ModelName + "." + "send",
				{
					"id": this.#SelectedRowIds[ I ]
				},
				false
			);
			
			JSONRPCRequests.push( JSONRPCRequest );
			
		}
		
		
		this.#APIClient.Batch(

			JSONRPCRequests,
			
			this.UIButtonSendClickOnSuccess.bind( this ),
			this.UIButtonSendClickOnError.bind( this )
			
		);
		
	}
	
	UIButtonSendClickOnSuccess( 
		JSONRPCResponses
	){
		
		this.#InProcessSend = false;
		
		this.#SelectedRowIds.splice( 0, this.#SelectedRowIds.length );
		
		
		let UISVG = this.#UIButtonSend.PropGet( "Children" )[ 0 ];
		
		UISVG.PropSet(
			"SX", 
			{
				
			}
		);
		
		
		let SuccessCount = 0;
		
		let ErrorCount = 0;
		
		let Rows = [ ];
		
		let Errors = [ ];
		
		for(
			let I = 0;
			I < JSONRPCResponses.length;
			I++
		){
			
			if( ( JSONRPCResponses[ I ].IsSuccess( ) ) === false ){
				
				if( JSONRPCResponses[ I ].Error( ).message ){
				
					Errors.push( JSONRPCResponses[ I ].Error( ).message );
				
				}
					
				ErrorCount = ErrorCount + 1;
				
			} else {
				
				SuccessCount = SuccessCount + 1;
				
				Rows.push( JSONRPCResponses[ I ].Result( ) );
				
			}
			
		}
		
		
		this.#UIGrid.RowsSet( Rows );
		
		
		alert( "Success: " + SuccessCount + ", Errors: " + ErrorCount + "\n" + "( " + Errors.join( ", " ) + " )" );

	}
	
	UIButtonSendClickOnError( 
		Err
	){
		
		console.log( "Error", Err );
		
		
		this.#InProcessSend = false;
		
		
		let UISVG = this.#UIButtonSend.PropGet( "Children" )[ 0 ];
		
		UISVG.PropSet(
			"SX", 
			{
				
			}
		);
		
		
		this.#SelectedRowIds.splice( 0, this.#SelectedRowIds.length );
		
		
		alert( Err.message );
		
	}
	
	UIButtonDeleteClick(
	
	){
		
		this.#InProcessDelete = true;
		
		
		this.#UIButtonSend.PropSet( "Disabled", true );
		
		this.#UIButtonRecreate.PropSet( "Disabled", true );
		
		this.#UIButtonDelete.PropSet( "Disabled", true );
		
		
		let JSONRPCRequests = [ ];
		
		for(
			let I = 0;
			I < this.#SelectedRowIds.length;
			I++
		){
			
			let JSONRPCRequest = new JSONRPCRequestT(
				this.#ModelName + "." + "delete_by_id",
				{
					"id": this.#SelectedRowIds[ I ]
				},
				false
			);
			
			JSONRPCRequests.push( JSONRPCRequest );
			
		}
		
		
		this.#APIClient.Batch(

			JSONRPCRequests,
			
			this.UIButtonDeleteClickOnSuccess.bind( this ),
			this.UIButtonDeleteClickOnError.bind( this )
			
		);
		
	}
	
	UIButtonDeleteClickOnSuccess( 
		JSONRPCResponses
	){
		
		this.#InProcessDelete = false;
		
		
		let HasErrors = false;
		
		for(
			let I = 0;
			I < JSONRPCResponses.length;
			I++
		){
			
			if( ( JSONRPCResponses[ I ].IsSuccess( ) ) === false ){
					
				HasErrors = true;
				
			}
			
		}
		
		if( HasErrors === true ){
			
			alert( "Chyba" );
			
			return;
			
		}
		
		
		alert( "Success" );
		

		this.#UIGrid.RowsDelete( this.#SelectedRowIds );
		
		
		this.#SelectedRowIds.splice( 0, this.#SelectedRowIds.length );

	}
	
	UIButtonDeleteClickOnError( 
		Err
	){
		
		console.log( "Error", Err );
		
		
		this.#InProcessDelete = false;
		
		this.#SelectedRowIds.splice( 0, this.#SelectedRowIds.length );
		
		
		alert( Err.message );
		
	}
	
	OnSelectionChanged( 
		Ev
	){

		console.log( "ViewPageEmploymentDepartnentInfosT.onSelectionChanged", Ev );
		
		
		let SelectedNodes = Ev.api.getSelectedNodes( );
		
		
		if( SelectedNodes.length === 0 ){
			
			this.#UIButtonSend.PropSet( "Disabled", true );
			
			this.#UIButtonRecreate.PropSet( "Disabled", true );
			
			this.#UIButtonDelete.PropSet( "Disabled", true );
			
			return;
			
		}
		
		if( 
			( this.#InProcessSend === true ) ||
			( this.#InProcessRecreate === true ) || 
			( this.#InProcessDelete === true )
		){
			
			this.#UIButtonSend.PropSet( "Disabled", true );
			
			this.#UIButtonRecreate.PropSet( "Disabled", true );
			
			this.#UIButtonDelete.PropSet( "Disabled", true );
			
			return;
			
		}
		
		
		let IsSendDisabled = false;
		
		let IsRecreateDisabled = false;
		
		let IsDeleteDisabled = false;
		
		
		this.#SelectedRowIds = [ ];
		
		
		for(
			let I = 0;
			I < SelectedNodes.length;
			I++
		){
			
			let SelectedNode = SelectedNodes[ I ];
			
			let SelectedRow = SelectedNode.data;
			
			
			this.#SelectedRowIds.push( SelectedRow.id );
		
			
			if( IsSendDisabled === false ){
		
				if( SelectedRow.response_status === null ){
					
					IsSendDisabled = false;
					
					for( let I2 in SelectedRow ){
						
						if ( this.GridCellHighlightIsErrorField( I2, SelectedRow[ I2 ], SelectedRow.notification_type ) === true ){
							
							IsSendDisabled = true;
							
							break;
							
						}
						
					}
					
				} else {
				
					IsSendDisabled = true;
					
				}
			
			}
		
		
			if( IsRecreateDisabled === false ){
		
				if( SelectedRow.response_status === null ){
					
					IsRecreateDisabled = true;
					
				} else {
					
					if( SelectedRow.response_status === "OK" ){
					
						IsRecreateDisabled = true;
					
					} else {
				
						IsRecreateDisabled = false;
					
					}
					
				}
			
			}
			
		}
		
		
		this.#UIButtonSend.PropSet( "Disabled", IsSendDisabled );
		
		this.#UIButtonRecreate.PropSet( "Disabled", IsRecreateDisabled );
		
		this.#UIButtonDelete.PropSet( "Disabled", IsDeleteDisabled );
		
	}
	
	UIPageContentOnReady( ){
		
		console.log( "ViewPageEmploymentDepartnentInfosT.UIPageContentOnReady" );
		
		
		this.#UIPageContent.TopBarSet( this.#UIPageContentTopBar );
		
		this.#UIPageContent.GridSet( this.#UIGrid );
		
	}
	
	UIButtonRecreateClick(
	
	){
		
		this.#InProcessRecreate = true;
		
		
		this.#UIButtonSend.PropSet( "Disabled", true );
		
		this.#UIButtonRecreate.PropSet( "Disabled", true );
		
		this.#UIButtonDelete.PropSet( "Disabled", true );
		
		
		let JSONRPCRequests = [ ];
		
		for(
			let I = 0;
			I < this.#SelectedRowIds.length;
			I++
		){
			
			let JSONRPCRequest = new JSONRPCRequestT(
				this.#ModelName + "." + "recreate",
				{
					"id": this.#SelectedRowIds[ I ]
				},
				false
			);
			
			JSONRPCRequests.push( JSONRPCRequest );
			
		}
		
		
		this.#APIClient.Batch(

			JSONRPCRequests,
			
			this.UIButtonRecreateClickOnSuccess.bind( this ),
			this.UIButtonRecreateClickOnError.bind( this )
			
		);
		
	}
	
	UIButtonRecreateClickOnSuccess( 
		JSONRPCResponses
	){
		
		this.#InProcessRecreate = false;
		
		this.#SelectedRowIds.splice( 0, this.#SelectedRowIds.length );
		
		
		let Rows = [ ];
		
		let HasErrors = false;
		
		for(
			let I = 0;
			I < JSONRPCResponses.length;
			I++
		){
			
			if( ( JSONRPCResponses[ I ].IsSuccess( ) ) === false ){
					
				HasErrors = true;
				
			} else {
				
				Rows.push( JSONRPCResponses[ I ].Result( ) );
				
			}
			
		}
		
		if( HasErrors === true ){
			
			alert( "Chyba" );
			
			return;
			
		}
		
		
		alert( "Success" );
		

		this.#UIGrid.RowsAdd( Rows );

	}
	
	UIButtonRecreateClickOnError( 
		Err
	){
		
		console.log( "Error", Err );
		
		
		this.#InProcessRecreate = false;
		
		this.#SelectedRowIds.splice( 0, this.#SelectedRowIds.length );
		
		
		alert( Err.message );
		
	}
	
	UIGridOnReady( ){
		
		let ConfigModels = this.#Storage.Get( "config.models" );
		
		let ModelId = null;
		
		for(
			let I = 0;
			I < ConfigModels.length;
			I++
		){
			
			if( ConfigModels[ I ].name === this.#ModelName ){
				
				ModelId = ConfigModels[ I ].id;
				
				break;
				
			}
			
		}
		
		
		if( ModelId === null ){
			
			throw new Error( "Invalid model" );
			
		}
		
		
		this.#APIClient.Batch( 
		
			[ 

				new JSONRPCRequestT(
					"properties.browse_by_model_id",
					{
						"model_id": ModelId
					},
					false
				),
				
				new JSONRPCRequestT(
					this.#ModelName + "." + "browse_all",
					{
						"include_deleted": false
					},
					false
				)
				
			], 
			
			this.UIGridOnReadyOnSuccess.bind( this ), 
		
			this.UIGridOnReadyOnError.bind( this )
			
		);
		
	}
	
	UIGridOnReadyOnSuccess( Result ){
			
		console.log( "ViewPageEmploymentDepartnentInfosT.UIGridOnReadyOnSuccess", Result );
		

		if( ( Result[ 0 ].IsSuccess( ) === false ) || ( Result[ 1 ].IsSuccess( ) === false ) ){
			
			alert( "Temp error" );
			
			throw new Error( "Invalid response" );
			
		}
		
		this.#Properties = Result[ 0 ].Result( );
			
		this.#Data = Result[ 1 ].Result( );
		
		
		let ColDefs = this.#UIGridColumnCreator.CreateFromProperties( 
			this.#Properties
		);
		
	
		for( 
			let I of ColDefs.keys( )
		){
			
			ColDefs.get( I ).cellStyle = this.GridCellStyle.bind( this )
			
		}
		
		ColDefs.get( "surname" ).headerCheckboxSelection = true;
		 
		ColDefs.get( "surname" ).checkboxSelection = true;
		
		ColDefs.get( "created_at" ).sort = "desc";
		
		
		this.#UIGrid.ColDefsSet( [ ...ColDefs.values( ) ] );
		
			
		this.#UIGrid.RowDataSet( this.#Data );

	}
	
	GridCellHighlightErrorIgnoreFields( ){
		
		return [ 
			"id",
			"created_by",
			"created_at",
			"updated_by",
			"updated_at",
			"deleted_by",
			"deleted_at",
			
			"request_at",
			"request_by",
			"response_code",
			"response_status",
			"response_message",
			"response_full",
			
			"temporary_stay_address_municipality",
			"temporary_stay_address_street",
			"temporary_stay_address_number",
			"temporary_stay_address_orientation_number",
			
			"execution_address_municipality",
			"execution_address_street",
			"execution_address_number",
			"execution_address_orientation_number",
			
			"agency_address_municipality",
			"agency_address_street",
			"agency_address_number",
			"agency_address_orientation_number",
			
			"customer_legal_address_municipality",
			"customer_legal_address_street",
			"customer_legal_address_number",
			"customer_legal_address_orientation_number",
			
			"pin",
			"execution_address_extension"
		];
		
	}
	
	GridCellStyle( Params ){
		
		if( this.GridCellHighlightIsError( Params ) === true ){
			
			return { backgroundColor: "#FA8072" };
			
		} else {
			
			return { backgroundColor: "white" };
			
		}
		
	}
	
	GridCellHighlightIsError( Params ){
		
		return this.GridCellHighlightIsErrorField(
			Params.colDef.field,
			Params.value,
			Params.data[ "notification_type" ]
		);
		
	}
	
	GridCellHighlightIsErrorField( Field, Value, NotificationType ){
		
		let Ignore = this.GridCellHighlightErrorIgnoreFields( );
		
		
		console.log( Field, Value, Ignore.indexOf( Field ), Ignore );
		
		if( Ignore.indexOf( Field ) !== -1 ){
				
			return false;
				
		}
				
		
		if( Value !== null && Value !== "" && Value !== " " ){
			
			return false;
			
		} else {
				
			if( ( Field === "execution_to" ) || ( Field === "termination_date" ) ){
				
				if( NotificationType === 3n ) {

					return true;
					
				} else {
				
					return false;
				
				}
				
			} else {
				
				return true;
			
			}
		
		}
		
	}

	UIGridOnReadyOnError( Err ){
		
		console.log( "ViewPageEmploymentDepartnentInfosT.UIGridOnReadyOnError", Err );
		
		
		alert( Err.message );
		
	}
	
	UIPageContentGet(
	
	){
		
		return this.#UIPageContent;
		
	}
	
	OnColumnChangeSuccess(
		AgGridColumnChangeEvent,
		Field,
		OldValue,
		Response
	){
		
		if( Response.IsSuccess( ) === false ) {
			
			AgGridColumnChangeEvent.node.data[ Field ] = OldValue;
			
			this.#UIGrid.RowsSet( [ AgGridColumnChangeEvent.node.data ] );
			
			alert( Response.Error( ).message );
		
		} else {
			
			this.#UIGrid.RowsSet( [ Response.Result( ) ] );
			
		}
		
	}
	
	OnColumnChangeError( 
		AgGridColumnChangeEvent,
		Field,
		OldValue,
		Err
	){
		
		alert( Err.message );
		
	}
	
	OnColumnChange(
		AgGridColumnChangeEvent
	){
		
		console.log( AgGridColumnChangeEvent );
		
		
		let Field = AgGridColumnChangeEvent.colDef.field;
		
		let NewValue = AgGridColumnChangeEvent.newValue;
		
		let OldValue = AgGridColumnChangeEvent.oldValue;
		
		let Id = AgGridColumnChangeEvent.data.id;
		
		
		let Params = {
			
		};
		
		
		Params[ "id" ] = Id;
		
		Params[ Field ] = NewValue;
		

		this.#APIClient.Request(
		
			new JSONRPCRequestT(
				this.#ModelName + "." + "edit_by_id",
				Params,
				false
			),
			
			this.OnColumnChangeSuccess.bind( this, AgGridColumnChangeEvent, Field, OldValue ),
			
			this.OnColumnChangeError.bind( this, AgGridColumnChangeEvent, Field, OldValue )
			
		);
		
	}

};

export default ViewPageEmploymentDepartnentInfosT;
