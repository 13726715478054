import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { GetApp } from "@material-ui/icons";

import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";

import SimpleLoader from "../SimpleLoader.js";

const EvaluationModal = ({ open, onClose, employeeId, month, year }) => {
  const { t } = useTranslation();

  const [evaluation, setEvaluation] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      fetchEvaluation();
    }
  }, [open]);

  const fetchEvaluation = async () => {
    try {
      setLoading(true);
      const response = await HttpService.get(
        UrlService.apiDomain() +
          `api/evaluate_coordinator/${employeeId}/${month}/${year}`
      );
      const data = await response.data;
      setEvaluation(data);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    try {
      const docContent = `
            <html xmlns:v="urn:schemas-microsoft-com:vml"
            xmlns:o="urn:schemas-microsoft-com:office:office"
            xmlns:w="urn:schemas-microsoft-com:office:word"
            xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
            xmlns="http://www.w3.org/TR/REC-html40">
            <head>
              <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
              <title>${t("Hodnocení koordinátora")}</title>
              <style>
                body { font-family: Arial, sans-serif; }
                pre { white-space: pre-wrap; font-family: Arial, sans-serif; }
              </style>
            </head>
            <body>
            <h1>${t("Hodnocení koordinátora")}</h1>
            <pre>${evaluation}</pre>
            </body>
            </html>`;

      const blob = new Blob(["\ufeff", docContent], {
        type: "application/msword",
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${t("Hodnocení koordinátora")}.doc`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      alert("Error creating DOC file:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <Box
        sx={{
          p: 2,
          display: "flex",
          width: 1,
          justifyContent: "space-between",
          alignItems: "center",
          flexShrink: 0,
        }}
      >
        <DialogTitle>{t("Hodnocení koordinátora")}</DialogTitle>
        <Box>
          <IconButton
            onClick={handleDownload}
            disabled={loading || !evaluation}
          >
            <GetApp />
          </IconButton>
          <Button onClick={onClose} color="primary">
            {t("Zavřít")}
          </Button>
        </Box>
      </Box>

      <DialogContent>
        {loading ? (
          <SimpleLoader />
        ) : (
          <DialogContentText>
            <div
              dangerouslySetInnerHTML={{ __html: `<pre>${evaluation}</pre>` }}
            />
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EvaluationModal;
