import { useState, useContext, useMemo, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
} from "@material-ui/core";

import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import useSettings from "../../hooks/useSettings";
import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../contexts/DataContext";
import SimpleLoader from "../../components/SimpleLoader.js";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const Vocalls = ({ employee_id }) => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const { data: contextData, isLoading } = useContext(DataContext);

  const customComparator = (valueA, valueB) => {
    if (valueA === null || valueB === null) {
      return valueA === valueB ? 0 : valueA === null ? -1 : 1;
    }
    if (typeof valueA === "string" && typeof valueB === "string") {
      return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    } else {
      // return default value for non-string and non-number values
      return 0;
    }
  };

  let lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  const allCampaigns = contextData?.vocalls_campaigns
    ?.filter((item) => item.is_visible)
    ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  const [open, setOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(
    allCampaigns[0].id || null
  );

  const [rowdata, setRowdata] = useState([]);
  const [dataEmployees, setDataEmployees] = useState({});
  const [loading, setLoading] = useState(true);

  const [gridApi, setgridApi] = useState({});
  const [loctext, setloctext] = useState(lt);
  const [gridColumnApi, setgridColumnApi] = useState({});

  const gtr = () => {
    setLoading(true);
    HttpService.post(
      UrlService.apiDomain() + "api/vocalls_contacts/get/all_by_employee",
      {
        data: {
          employee_id,
        },
      }
    )
      .then((res) => {
        if (!res.data.success) {
          alert(res.data.message);
          setLoading(false);
          return;
        }
        setRowdata(res.data.data);
        setDataEmployees(res.data.data_employees);
        setLoading(false);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCampaignChange = (e) => {
    setSelectedCampaign(e.target.value);
  };

  const handleAddEmployee = async () => {
    try {
      const response = await HttpService.post(
        UrlService.apiDomain() + `api/vocalls_contact/create`,
        {
          data: {
            employee_id,
            vocalls_campaign_id: selectedCampaign,
          },
        }
      );
      if (!response.data.success) {
        alert(response.data.message);
        return;
      }
      handleClose();
      alert("Osoba přidána");
      gtr();
    } catch (err) {
      console.error(err);
    }
  };

  const onGridReady = (params) => {
    setgridApi(params.api);
    setgridColumnApi(params.columnApi);
  };

  const onDragStopped = (params) => {};

  const defaultColDef = {
    flex: 1,
    minWidth: 120,
    resizable: true,
    sortable: true,
    suppressContextMenu: true,
    menuTabs: ["filterMenuTab", "columnsMenuTab"],
    filter: true,
    comparator: customComparator,
  };

  const frameworkComponents = {};

  const coldefs = useMemo(
    () => [
      {
        headerName: "#",
        valueGetter: "node.rowIndex + 1",
        lockPosition: "left",
        pinned: "left",
        maxWidth: 150,
        sortable: false,
        resizable: false,
        suppressMenu: true,
      },
      {
        headerName: t("Zaměstnanec"),
        field: "employee_id",
        valueGetter: (params) => {
          let employee = dataEmployees[params.data.employee_id];
          return `${employee?.surname} ${employee?.name}`;
        },
      },
      {
        headerName: t("Telefon"),
        valueGetter: (params) => {
          return params.data.request_data.phone;
        },
      },
      {
        headerName: t("Výsledek"),
        valueGetter: (params) => {
          return params.data.response_data?.callResult;
        },
      },
      {
        headerName: t("Přidal"),
        field: "created_by",
        valueGetter: (params) => {
          let employee = dataEmployees[params.data.created_by];
          return `${employee?.surname} ${employee?.name}`;
        },
      },
      {
        headerName: t("Přidáno"),
        field: "created_at",
        valueFormatter: (params) => {
          let d = new Date(params.value);

          let str =
            ("0" + d.getDate()).slice(-2) +
            "." +
            ("0" + (d.getMonth() + 1)).slice(-2) +
            "." +
            d.getFullYear() +
            " " +
            ("0" + d.getHours()).slice(-2) +
            ":" +
            ("0" + d.getMinutes()).slice(-2) +
            ":" +
            ("0" + d.getSeconds()).slice(-2);

          return params.value && params.value !== "1970-01-01 00:00:00"
            ? str
            : "";
        },
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: function (date1, date2) {
            function _monthToNum(date) {
              if (date === undefined || date === null || date === "") {
                return null;
              }

              var date_arr1 = date.split(" ");
              var date_arr = date_arr1[0].split(".");

              var yearNumber = parseInt(date_arr[2]);
              var monthNumber = parseInt(date_arr[1]);
              var dayNumber = parseInt(date_arr[0]);

              var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
              // 29/08/2004 => 20040829
              //console.log(result);
              return result;
            }

            var date1Number = _monthToNum(date1);
            var date2Number = _monthToNum(date2);

            if (date1Number === null && date2Number === null) {
              return 0;
            }
            if (date1Number === null) {
              return -1;
            }
            if (date2Number === null) {
              return 1;
            }

            return date1Number - date2Number;
          },
        },
        comparator: (a, b) => {
          if (a && b) {
            let aDate = new Date(a);
            let bDate = new Date(b);
            bDate.setHours(0, 0, 0, 0);
            return aDate.getTime() - bDate.getTime();
          }
          if (a) {
            return 1;
          }
          return -1;
        },
      },
      {
        headerName: t("Poslouchat"),
        valueGetter: (params) => {
          if (
            params.data.response_data?.callResult === "LISTENEDTOEND" ||
            params.data.response_data?.callResult === "PICKED"
          ) {
            return params.data.response_data?.recordUrl;
          } else return "";
        },
      },
    ],
    [t("cs"), dataEmployees]
  );

  useEffect(() => {
    gtr();
  }, []);

  return (
    <>
      <Helmet>
        <title>Vocalls | AWA Partners</title>
      </Helmet>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Prosím, vyberte kampaň")}
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <SimpleLoader />
          ) : (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                id="select"
                value={selectedCampaign}
                onChange={handleCampaignChange}
              >
                {allCampaigns?.map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Zrušit")}
          </Button>
          <Button onClick={handleAddEmployee} color="primary">
            {t("Ano")}
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          backgroundColor: "background.default",
          height: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                {t("Vocalls")}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                {t("Odeslat na Vocalls")}
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <div
              style={{ width: "100%", overflow: "auto", height: "500px" }}
              id="myGrid"
              className="ag-theme-material"
            >
              {loading || isLoading ? (
                <SimpleLoader />
              ) : (
                <AgGridReact
                  modules={AllCommunityModules}
                  rowData={rowdata}
                  columnDefs={coldefs}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  onDragStopped={onDragStopped}
                  frameworkComponents={frameworkComponents}
                  enableCellChangeFlash={false}
                  rowSelection={"single"}
                  suppressContextMenu={true}
                  localeText={loctext}
                  popupParent={document.querySelector("body")}
                />
              )}
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Vocalls;
