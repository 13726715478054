import { useFormik } from "formik";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";

import {
  Grid,
  TextField,
  Button,
  Box,
  Container,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";

import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";

const SendLink = ({
  handleClosePopover,
  employee_phone,
  employee_email,
  employee_id,
  link,
  overview,
  policyActions,
}) => {
  const { t } = useTranslation();

  let lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");

  const { settings } = useSettings();
  const [selectedLanguage, setSelectedLanguage] = useState("36");
  const [selectedRole, setSelectedRole] = useState(
    policyActions.includes("send_to_employee")
      ? "32"
      : policyActions.includes("add_supplier")
      ? "5"
      : ""
  );

  useEffect(() => {
    if (employee_phone === "ZOBRAZIT") {
      HttpService.get(
        UrlService.apiDomain() +
          "api/show_ondemand/" +
          employee_id +
          "/" +
          "phone"
      )
        .then((res) => {
          formik.setFieldValue("phone", res.data.ondemand);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (employee_email === "ZOBRAZIT") {
      HttpService.get(
        UrlService.apiDomain() +
          "api/show_ondemand/" +
          employee_id +
          "/" +
          "email"
      )
        .then((res) => {
          formik.setFieldValue("email", res.data.ondemand);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [employee_phone, employee_email]);

  const formik = useFormik({
    initialValues: {
      email: employee_email || "",
      id: employee_id || "",
      phone: employee_phone || "",
      link: link || "",
    },
    validationSchema: Yup.object({
      email: overview
        ? Yup.string().email("Nesprávný email")
        : Yup.string().email("Nesprávný email").required("Povinné"),
      phone: Yup.number(),
      link: Yup.string(),
    }),
    onSubmit: async (values) => {
      const data = {
        email: values.email,
        phone: values.phone,
        id: values.id,
        link: values.link,
        language: selectedLanguage,
        role: Number(selectedRole),
      };

      const url =
        overview && link ? "api/supplier_link_send" : "api/employee_role_set";

      try {
        const res = await HttpService.post(
          `${UrlService.apiDomain()}${url}`,
          data
        );

        if (!res.data.success) {
          toast.error(res.data.message);
          handleClosePopover();
          throw new Error(res.data.message);
        }
        formik.handleReset();
        handleClosePopover();
        toast.success("Odkaz odeslán");
      } catch (error) {
        alert(error);
      }
    },
  });

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 2,
      }}
    >
      <Container maxWidth={settings.compact ? "xl" : false}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" variant="h5">
              {t("Poslat odkaz")}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleClosePopover}>
              {t("Zavřít")}
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ p: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="language"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="English"
                />
                <FormControlLabel
                  value="45"
                  control={<Radio />}
                  label="Russian"
                />
                <FormControlLabel
                  value="36"
                  control={<Radio />}
                  label="Czech"
                />
              </RadioGroup>
            </FormControl>
            {!overview &&
              policyActions.includes("send_to_employee") &&
              policyActions.includes("add_supplier") && (
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="role"
                    value={selectedRole}
                    onChange={handleRoleChange}
                  >
                    <FormControlLabel
                      value="32"
                      control={<Radio />}
                      label="Zaměstnanec"
                    />
                    <FormControlLabel
                      value="5"
                      control={<Radio />}
                      label="Dodavatel"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            <TextField
              sx={{ mb: 2 }}
              name="email"
              label={t("Email")}
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={!!(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              sx={{ mb: 2 }}
              name="phone"
              label={t("Telefon")}
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              error={!!(formik.touched.phone && formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <Button type="submit" variant="contained" fullWidth color="primary">
              {t("Odeslat")}
            </Button>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default SendLink;
