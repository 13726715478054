import { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import ClipboardListIcon from "../../../icons/ClipboardList";
import KanbanCheckItem from "./KanbanCheckItem";
import { useTranslation } from "react-i18next";

const KanbanChecklistRoot = experimentalStyled("div")``;

const KanbanChecklist = (props) => {
  const { t } = useTranslation();
  const { card, checklist, ...other } = props;
  const [editingCheckItem, setEditingCheckItem] = useState(null);

  const handleCheckItemEditInit = (checkItemId) => {
    setEditingCheckItem(checkItemId);
  };

  const handleCheckItemEditCancel = () => {
    setEditingCheckItem(null);
  };

  const handleCheckItemEditComplete = () => {
    setEditingCheckItem(null);
  };

  const totalCheckItems = card.employeeNeeded;
  const completedCheckItems = checklist.checkItems.filter(
    (checkItem) => checkItem.state === "complete"
  ).length;
  const completePercentage =
    totalCheckItems === 0 ? 100 : (completedCheckItems / totalCheckItems) * 100;

  return (
    <KanbanChecklistRoot {...other}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box sx={{ mr: 3 }}>
          <ClipboardListIcon fontSize="small" />
        </Box>

        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
          }}
        >
          <Typography color="textPrimary" variant="h6">
            {t(checklist.name)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          mt: 1,
        }}
      >
        <Typography color="textSecondary" variant="caption">
          {Math.round(completePercentage)}%
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        >
          <LinearProgress
            color="primary"
            value={completePercentage}
            variant="determinate"
          />
        </Box>
      </Box>
      {checklist.checkItems.map((checkItem) => (
        <KanbanCheckItem
          card={card}
          cardId={card.id}
          checkItem={checkItem}
          checklistId={checklist.id}
          editing={editingCheckItem === checkItem.id}
          key={checkItem.id}
          onEditCancel={handleCheckItemEditCancel}
          onEditComplete={handleCheckItemEditComplete}
          onEditInit={() => handleCheckItemEditInit(checkItem.id)}
        />
      ))}
      <Box
        sx={{
          ml: 6,
          mt: 1,
        }}
      ></Box>
    </KanbanChecklistRoot>
  );
};

KanbanChecklist.propTypes = {
  card: PropTypes.object.isRequired,
  checklist: PropTypes.object.isRequired,
  sx: PropTypes.object,
};

export default KanbanChecklist;
